import { useState, useEffect } from 'react';
import axios from 'axios';
import { URL } from '../../config';

const useBusinessProfile = (key) => {
  const [loading, setLoading] = useState(true);
  const [businessProfileData, setBusinessProfileData] = useState({
    partySize: null,
    userId: null,
    businessName: '',
    businessAddress: '',
    businessPhoneNo: '',
    photo: '',
    futureTime: [],
    futureTimeToday: [],
    partySizes: [],
    timeLabel: '',
    allowGuestSelectSeat: false,
    allowGuestSelectSection: false,
    widgetMessageTitle: '',
    widgetMessage: '',
    onlinePaymentFee: null,
    date: new Date(),
    experiences: [],
    viewState: '',
    isOnlineEnabled: true
  });

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        // Fetch widget/me first
        const profileResponse = await axios.get(`${URL}/widget/me`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${key}`
          }
        });

        if (!isMounted) return;

        const profileData = profileResponse.data.data;
        const user = profileData.user;
        const { business_name, address, phone_no, profile_pic } = user.business_profile;

        // Set profile data first, regardless of experience API success
        setBusinessProfileData((prevData) => ({
          ...prevData,
          partySize: profileData.partySizes[0],
          userId: user.id,
          businessName: business_name,
          businessAddress: address,
          businessPhoneNo: phone_no,
          photo: profile_pic,
          futureTime: profileData.futureTime,
          futureTimeToday: profileData.futureTime,
          partySizes: profileData.partySizes,
          timeLabel: profileData.futureTime[0]?.label || '',
          allowGuestSelectSeat: Boolean(user.setting.allow_guest_select_seat),
          allowGuestSelectSection: Boolean(user.setting.allow_guest_select_section),
          widgetMessageTitle: user.setting.widget_note_title,
          widgetMessage: user.setting.widget_note_message,
          onlinePaymentFee: user.setting.online_booking_payment,
          date: new Date(profileData.currentTime),
          isOnlineEnabled: profileData.online_is_enabled
        }));

        // Fetch widget/experience/list, but handle failure gracefully
        try {
          const experienceResponse = await axios.get(`${URL}/widget/experience/list`, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${key}`
            },
            data: {}
          });

          if (isMounted) {
            setBusinessProfileData((prevData) => ({
              ...prevData,
              experiences: experienceResponse.data.data.experiences,
              viewState:
                experienceResponse.data.data.experiences.length === 0 ? 'TABLE_BOOKING' : 'INITIAL'
            }));
          }
        } catch (experienceError) {
          console.error('Experience API failed:', experienceError);
          if (isMounted) {
            setBusinessProfileData((prevData) => ({
              ...prevData,
              viewState: 'TABLE_BOOKING'
            }));
          }
        }
      } catch (error) {
        console.error('Profile API failed:', error);
        if (isMounted) {
          setBusinessProfileData((prevData) => ({
            ...prevData,
            viewState: 'TABLE_BOOKING'
          }));
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [key]);

  return { businessProfileData, loading };
};

export default useBusinessProfile;
