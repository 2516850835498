import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { IoIosCheckmarkCircleOutline } from 'react-icons/io';
import { FaMapMarkerAlt } from 'react-icons/fa';
import GoogleMap from '../../components/utils/GoogleMap';
import { RiPencilFill } from 'react-icons/ri';
import { MdOutlineCancel } from 'react-icons/md';
import { FiSearch } from 'react-icons/fi';
// Material-UI
import {
  Typography,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';

// Styles
import '../../assets/css/booking/bookingDetails.css';

// Hooks
import useBusinessProfile from '../../hooks/@booking/useBusinessProfile';
import getMyBooking from '../../hooks/@booking/useMyBooking';
import { cancelBooking } from '../../hooks/@booking/useCancelBooking';
import getWidgetBranding from '../../hooks/@common/getWidgetBranding';

// Utils
import { s3Bucket } from '../../config';
import moment from 'moment';

// Components
import BusinessAddress from './businessAddress';
import BookingCard from './bookingCard';
import ContactCard from './contactCard';
import PoweredBy from '../../components/common/powerdBy';

const bookingStatuses = [
  'Confirmed', // 0
  'ALL', // 1
  'Billed', // 2
  'Unbill', // 3
  'Seated', // 4
  'Unseat', // 5
  'Finished', // 6
  'No Show', // 7
  'Cancelled', // 8
  'Seat Not WaitList', // 9
  'WaitList', // 10
  'OverTime', // 11
  'Upcoming', // 12
  'Late', // 13
  'Need Attention', // 14
  'Partially Seated', // 15
  'Unfinished', // 16
  'Pending' // 17
];

const BookingDetails = () => {
  const [status, setStatus] = useState(0);
  const { key, id } = useParams();
  const { businessProfileData } = useBusinessProfile(key);
  const [invalid, setInvalid] = useState(false);
  const [myBooking, setMybooking] = useState(null);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const { businessName, businessAddress, photo } = businessProfileData;
  const history = useHistory();
  const [widgetBranding, setWidgetBranding] = useState({});
  const [isChangeAllowed, setIsChangeAllowed] = useState(true);

  const getStatusChip = (status) => {
    return (
      <Chip
        label={bookingStatuses[status]}
        style={{
          backgroundColor: '#fff',
          color: status === 8 ? '#f44336' : status === 0 ? '#4caf50' : '#000',
          fontWeight: 'bold',
          fontFamily: 'Inter',
          fontSize: '18px',
          textTransform: 'capitalize'
        }}
      />
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [myBookingRes, widgetBrandingRes] = await Promise.all([
          getMyBooking(id, key),
          getWidgetBranding(key)
        ]);

        setWidgetBranding(widgetBrandingRes.data);

        if (myBookingRes?.response?.status === 404) {
          setInvalid(true);
          return;
        }

        setMybooking(myBookingRes.data);
        setStatus(Number(myBookingRes.data.status));

        if (myBookingRes.data.owner.setting.allow_guest_request_cancel_reservation_duration) {
          const cancelDurationInSeconds =
            myBookingRes.data.owner.setting.allow_guest_request_cancel_reservation_duration;
          const bookingDate = moment(myBookingRes.data.booking_date);
          const cancelTime = bookingDate.subtract(cancelDurationInSeconds, 'seconds');
          const currentTime = moment();

          if (currentTime.isAfter(cancelTime)) {
            setIsChangeAllowed(false);
          }
        }
      } catch (error) {
        console.error('Error while fetching booking:', error);
        setInvalid(true);
      }
    };
    fetchData();
  }, [id, key]);

  const handleEditClick = () => {
    history.push({
      pathname: `/${key}/${id}/edit-booking`,
      state: {
        myBooking,
        businessName,
        businessAddress,
        widgetBranding
      }
    });
  };
  const handleCancelClick = () => {
    setOpenCancelDialog(true);
  };

  const handleCancel = async () => {
    try {
      const result = await cancelBooking(id, key);
      if (result.status === 200) {
        setStatus(8);
        setOpenCancelDialog(false);
      } else {
        console.error('Failed to cancel booking:', result.message);
      }
    } catch (error) {
      console.error('Error while cancelling booking:', error);
    }
  };

  const handleClose = () => {
    setOpenCancelDialog(false);
  };

  return (
    <div className="wabi-root booking-management">
      <div className="wabi-container">
        {!invalid ? (
          <>
            <p className="wabi-header-ttl">Manage Reservation</p>

            <div className="wabi-header">
              <BusinessAddress businessName={businessName} businessAddress={businessAddress} />
              <div className="wabi-header-status">
                <div className="business-icon rounded-lg">
                  <img
                    src={s3Bucket + photo}
                    alt={businessName}
                    style={{ width: '100%', height: '100%', borderRadius: '10px' }}
                  />
                </div>
                <div className="">
                  <div className="check-step">
                    {status === 8 ? (
                      <MdOutlineCancel className="check-icon" style={{ color: '#f44336' }} />
                    ) : status === 0 ? (
                      <IoIosCheckmarkCircleOutline
                        className="check-icon"
                        style={{ color: '#4caf50' }}
                      />
                    ) : (
                      <IoIosCheckmarkCircleOutline className="check-icon" />
                    )}
                    <div className="mb-0">{getStatusChip(status)}</div>
                  </div>
                </div>
              </div>
            </div>
            <p className="wabi-header-second" style={{ marginBottom: '40px' }}>
              Booking Detail
            </p>

            <div>
              <div className="booking-body">
                <div>
                  {/* Date and Time */}

                  <BookingCard
                    firstName={myBooking?.guest?.first_name || ''}
                    lastName={myBooking?.guest?.last_name || ''}
                    bookingDate={myBooking?.booking_date || ''}
                    partySize={myBooking?.party_size || 0}
                  />

                  <div className="detail-item">
                    <FaMapMarkerAlt className="icon" />
                    <span className="wabi-subtitle">{businessAddress}</span>
                  </div>
                  {myBooking?.notes !== null && (
                    <div className="detail-item">
                      <RiPencilFill className="icon" />
                      <span className="wabi-subtitle">{myBooking?.notes}</span>
                    </div>
                  )}
                </div>
                <GoogleMap
                  businessName={businessName}
                  businessAddress={businessAddress}
                  style={{ height: '160px', width: '100%', borderRadius: '10px' }}
                />
              </div>

              {status === 0 &&
              myBooking?.owner.setting.allow_guest_request_cancel_reservation &&
              isChangeAllowed ? (
                <>
                  <div className="edit-btn-wrapper" style={{ marginTop: 60 }}>
                    <Button
                      variant="outlined"
                      style={{
                        backgroundColor: '#000',
                        color: '#fff',
                        borderColor: '#fff',
                        borderRadius: '5px',
                        padding: '10px 0',
                        width: '100%',
                        textTransform: 'capitalize',
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        textAlign: 'center'
                      }}
                      onClick={handleEditClick}
                    >
                      <span>Edit Booking</span>
                    </Button>
                    <Button
                      variant="outlined"
                      style={{
                        backgroundColor: '#000',
                        color: '#fff',
                        borderColor: '#fff',
                        borderRadius: '5px',
                        padding: '10px 0',
                        width: '100%',
                        textTransform: 'capitalize',
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        textAlign: 'center'
                      }}
                      onClick={handleCancelClick}
                    >
                      <span>Cancel</span>
                    </Button>
                  </div>
                </>
              ) : (
                <div
                  className="contact-wrapper"
                  style={{ marginTop: '60px', marginBottom: '80px' }}
                >
                  <ContactCard
                    businessName={businessName}
                    businessAddress={businessAddress}
                    email={myBooking?.owner.email || ''}
                    phone={myBooking?.owner.business_profile.phone_no || ''}
                  />
                </div>
              )}
            </div>
          </>
        ) : (
          <div className="">
            <div className="wabi-header-ttl">
              <div className="check-step">
                <FiSearch className="check-icon" />
                <p
                  style={{
                    fontWeight: 'bold',
                    fontFamily: 'Inter',
                    fontSize: '28px',
                    marginBottom: '0',
                    marginLeft: '10px',
                    textAlign: 'left'
                  }}
                >
                  Booking not found
                </p>
              </div>
            </div>
            <p>
              The booking you are looking for could not be found. Please verify your booking details
              and try again.
            </p>
            <p className="wabi-header-second" style={{ marginTop: '80px' }}>
              Need Help?
            </p>
            <div className="contact-wrapper" style={{ marginTop: '20px', marginBottom: '80px' }}>
              <ContactCard
                businessName={businessName}
                businessAddress={businessAddress}
                email={myBooking?.owner.email || ''}
                phone={myBooking?.owner.business_profile.phone_no || ''}
              />
            </div>
          </div>
        )}
      </div>

      {/* Confirmation Dialog with Black Theme */}
      <Dialog
        open={openCancelDialog}
        onClose={handleClose}
        PaperProps={{
          style: {
            borderRadius: '10px' // Optional: round corners
          }
        }}
      >
        <DialogTitle style={{}}>Confirm Cancellation</DialogTitle>
        <DialogContent>
          <Typography style={{}}>Would you like to cancel your reservation?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            No
          </Button>
          <Button onClick={handleCancel} color="secondary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <PoweredBy className="pb-4" />
    </div>
  );
};

export default BookingDetails;
