import { TABLETYPE } from './enums/enum';

// Table images
import singleTable from '../assets/images/booking/tables/single-table.png';
import twoSingleTable from '../assets/images/booking/tables/two-single-table.png';
import threeSingleTable from '../assets/images/booking/tables/three-single-table.png';
import singlePairTable from '../assets/images/booking/tables/single-pair-table.png';
import twoSinglePairTable from '../assets/images/booking/tables/two-single-pair-table.png';
import threeSinglePairTable from '../assets/images/booking/tables/three-single-pair-table.png';
import fourSinglePairTable from '../assets/images/booking/tables/four-single-pair-table.png';
import fourPersonSingleTable from '../assets/images/booking/tables/four-person-single-table.png';
import fourPersonRoundTable from '../assets/images/booking/tables/four-person-round-table.png';
import sixPersonRoundTable from '../assets/images/booking/tables/six-person-round-table.png';
import eightPersonRoundTable from '../assets/images/booking/tables/eight-person-round-table.png';
import tenPersonRoundTable from '../assets/images/booking/tables/ten-person-round-table.png';
import halfSeatRoundTable from '../assets/images/booking/tables/half-seat-round-table.png';
import twoPersonRoundTable from '../assets/images/booking/tables/two-person-round-table.png';

const _radToDeg = (rad) => {
  let deg = Math.ceil((rad * 180) / Math.PI);
  if (deg >= 360) {
    deg = deg - 360;
  }
  return deg;
};

export const getRadWidth = (rad, tableType) => {
  let radToDeg = _radToDeg(rad);
  let width = getTableWidth(tableType);
  let height = getTableHeight(tableType);

  switch (radToDeg) {
    case 0:
    case 180:
      return width;
    default:
      return height;
  }
};

export const getRadHeight = (rad, tableType) => {
  let radToDeg = _radToDeg(rad);
  let width = getTableWidth(tableType);
  let height = getTableHeight(tableType);

  switch (radToDeg) {
    case 0:
    case 180:
      return height;
    default:
      return width;
  }
};

export const tableNamePositionLeft = (tableType) => {
  switch (tableType) {
    case TABLETYPE.fourPersonRoundTable:
    case TABLETYPE.sixPersonRoundTable:
    case TABLETYPE.eightPersonRoundTable:
    case TABLETYPE.halfSeatRoundTable:
    case TABLETYPE.twoPersonRoundTable:
      return '50%';
    default:
      return 5;
  }
};

export const getPositionLeft = (rad, tableType) => {
  let radToDeg = _radToDeg(rad);
  let width = getTableWidth(tableType);
  let height = getTableHeight(tableType);

  switch (radToDeg) {
    case 0:
      return 0;
    case 90:
      return height;
    case 180:
      return width;
    default:
      return 0;
  }
};

export const getPositionTop = (rad, tableType) => {
  let radToDeg = _radToDeg(rad);
  let width = getTableWidth(tableType);
  let height = getTableHeight(tableType);

  switch (radToDeg) {
    case 0:
      return 0;
    case 90:
      return 0;
    case 180:
      return height;
    default:
      return width;
  }
};

export const tableNamePositionLeftTranslate = (tableType) => {
  switch (tableType) {
    case TABLETYPE.fourPersonRoundTable:
    case TABLETYPE.sixPersonRoundTable:
    case TABLETYPE.eightPersonRoundTable:
    case TABLETYPE.halfSeatRoundTable:
    case TABLETYPE.twoPersonRoundTable:
      return '-57%';
    default:
      return 0;
  }
};

export const tableNamePositionMarginLeft = (tableType, rad) => {
  let radToDeg = _radToDeg(rad);
  // let width = getTableWidth(tableType)
  // let height = getTableHeight(tableType)

  switch (tableType) {
    case TABLETYPE.singleTable:
    case TABLETYPE.twoSingleTable:
    case TABLETYPE.threeSingleTable:
      switch (radToDeg) {
        case 90:
          return 5;
        case 180:
          return 7;
        case 270:
          return 0;
        default:
          break;
      }
      break;
    case TABLETYPE.singlePairTable:
    case TABLETYPE.twoSinglePairTable:
    case TABLETYPE.threeSinglePairTable:
    case TABLETYPE.fourSinglePairTable:
      switch (radToDeg) {
        case 90:
          return 0;
        case 270:
          return 9;
        case 180:
        case 0:
          return 8;
        default:
          break;
      }
      break;
    case TABLETYPE.fourPersonSingleTable:
      return 10;
    case TABLETYPE.fourPersonRoundTable:
    case TABLETYPE.sixPersonRoundTable:
    case TABLETYPE.eightPersonRoundTable:
    case TABLETYPE.halfSeatRoundTable:
    case TABLETYPE.twoPersonRoundTable:
      return 0;
    case TABLETYPE.tenPersonRoundTable:
      return 0;
    default:
      break;
  }
};

export const radToDeg = (rad) => {
  return _radToDeg(rad);
};

// Get Table Img
export const getTableImg = (tableType) => {
  switch (tableType) {
    case TABLETYPE.singleTable:
      return singleTable;
    case TABLETYPE.twoSingleTable:
      return twoSingleTable;
    case TABLETYPE.threeSingleTable:
      return threeSingleTable;
    case TABLETYPE.singlePairTable:
      return singlePairTable;
    case TABLETYPE.twoSinglePairTable:
      return twoSinglePairTable;
    case TABLETYPE.threeSinglePairTable:
      return threeSinglePairTable;
    case TABLETYPE.fourSinglePairTable:
      return fourSinglePairTable;
    case TABLETYPE.fourPersonSingleTable:
      return fourPersonSingleTable;
    case TABLETYPE.fourPersonRoundTable:
      return fourPersonRoundTable;
    case TABLETYPE.sixPersonRoundTable:
      return sixPersonRoundTable;
    case TABLETYPE.eightPersonRoundTable:
      return eightPersonRoundTable;
    case TABLETYPE.tenPersonRoundTable:
      return tenPersonRoundTable;
    case TABLETYPE.halfSeatRoundTable:
      return halfSeatRoundTable;
    case TABLETYPE.twoPersonRoundTable:
      return twoPersonRoundTable;
    default:
      break;
  }
};

export const tableNamePositionMarginTop = (tableType, rad) => {
  let radToDeg = _radToDeg(rad);
  // let width = getTableWidth(tableType)
  // let height = getTableHeight(tableType)

  switch (tableType) {
    case TABLETYPE.singleTable:
    case TABLETYPE.twoSingleTable:
    case TABLETYPE.threeSingleTable:
      switch (radToDeg) {
        case 90:
          return 5;
        case 180:
          return 10;
        case 270:
          return 10;
        default:
          break;
      }
      break;
    case TABLETYPE.singlePairTable:
    case TABLETYPE.twoSinglePairTable:
    case TABLETYPE.threeSinglePairTable:
    case TABLETYPE.fourSinglePairTable:
      switch (radToDeg) {
        case 90:
        case 270:
          return 10;
        case 180:
          return 7;
        case 0:
          return 2;
        default:
          break;
      }
      break;
    case TABLETYPE.fourPersonSingleTable:
      return 10;
    case TABLETYPE.fourPersonRoundTable:
    case TABLETYPE.sixPersonRoundTable:
    case TABLETYPE.eightPersonRoundTable:
    case TABLETYPE.halfSeatRoundTable:
    case TABLETYPE.twoPersonRoundTable:
    case TABLETYPE.tenPersonRoundTable:
      return 15;
    default:
      break;
  }
};

export const getTableWidth = (tableType) => {
  switch (tableType) {
    case TABLETYPE.singleTable:
    case TABLETYPE.twoSingleTable:
    case TABLETYPE.threeSingleTable:
      return 89;
    case TABLETYPE.singlePairTable:
    case TABLETYPE.twoSinglePairTable:
    case TABLETYPE.threeSinglePairTable:
    case TABLETYPE.fourSinglePairTable:
    case TABLETYPE.fourPersonSingleTable:
      return 100;
    case TABLETYPE.fourPersonRoundTable:
    case TABLETYPE.sixPersonRoundTable:
    case TABLETYPE.eightPersonRoundTable:
    case TABLETYPE.halfSeatRoundTable:
    case TABLETYPE.twoPersonRoundTable:
      return 131;
    case TABLETYPE.tenPersonRoundTable:
      return 136;
    default:
      break;
  }
};

export const getTableHeight = (tableType) => {
  switch (tableType) {
    case TABLETYPE.singleTable:
      return 78;
    case TABLETYPE.twoSingleTable:
      return 156;
    case TABLETYPE.threeSingleTable:
      return 234;
    case TABLETYPE.singlePairTable:
      return 80;
    case TABLETYPE.twoSinglePairTable:
      return 160;
    case TABLETYPE.threeSinglePairTable:
      return 240;
    case TABLETYPE.fourSinglePairTable:
      return 320;
    case TABLETYPE.fourPersonSingleTable:
      return 100;
    case TABLETYPE.fourPersonRoundTable:
    case TABLETYPE.sixPersonRoundTable:
    case TABLETYPE.eightPersonRoundTable:
    case TABLETYPE.tenPersonRoundTable:
      return 131;
    case TABLETYPE.halfSeatRoundTable:
      return 146;
    case TABLETYPE.twoPersonRoundTable:
      return 116;
    default:
      break;
  }
};

export const getShiftColorCode = (value, shiftColor) => {
  if (value.match(/(All Day|All day|all day|allday|Allday)/g)) {
    return '#AAFAA1';
  } else if (value.match(/(Breakfast|breakfast)/g)) {
    return '#7A79FF';
  } else if (value.match(/(Lunch|lunch)/g)) {
    return '#5D5CDE';
  } else if (value.match(/(Dinner|dinner)/g)) {
    return '#FC6C6C';
  } else if (value.match(/(Over Night|Over night|over night)/g)) {
    return '#FF8652';
  } else {
    return shiftColor;
  }
};
const threshold = 150;

export function getRelativeLuminance(hex) {
  hex = hex?.replace(/^#/, '');
  const hexRegex = /^[0-9A-F]{6}$/i;

  if (!hexRegex.test(hex)) {
    return 'inherit';
  }
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;

  return luminance > threshold ? 'black' : 'white';
}
