import React, { useState, useEffect } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';

// Styles
import '../../assets/css/booking/bookingDetails.css';

// Hooks
import { updateMyBooking } from '../../hooks/@booking/updateMyBooking';

// Components
import EventBookFilter from '../../components/events/BookFilter';
import BookingCard from './bookingCard';
import ContactCard from './contactCard';
import { FaArrowDown } from 'react-icons/fa';
import { Button } from '@material-ui/core';
import PoweredBy from '../../components/common/powerdBy';

const EditBooking = () => {
  const history = useHistory();
  const { key, id } = useParams();
  const { state } = useLocation();
  const customWidgetBranding = {
    background_colour: '#fff',
    accent_colour: '#000',
    font: 'inherit'
  };
  const [formData, setFormData] = useState({
    booking_date: state?.myBooking?.booking_date,
    first_name: state?.myBooking?.guest?.first_name,
    last_name: state?.myBooking?.guest?.last_name,
    partySize: state?.myBooking?.party_size,
    widgetBranding: {
      ...state?.widgetBranding,
      ...customWidgetBranding
    }
  });
  const [selectedDate, setSelectedDate] = useState(formData.booking_date);
  const [selectedPartySize, setSelectedPartySize] = useState(formData.partySize);
  const [errorMessage, setErrorMessage] = useState('');
  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      booking_date: selectedDate,
      partySize: selectedPartySize
    }));
  }, [selectedDate, selectedPartySize]);

  const handleConfirm = async () => {
    const params = {
      booking_time: formData.time,
      date: formData.date,
      party_size: formData.partySize,
      shift_id: formData.shift_id,
      floor_id: formData.floor_id,
      experience_id: formData.experience_id,
      email: state.myBooking.guest.email,
      phone_number: state.myBooking.guest.phone,
      first_name: formData.first_name,
      last_name: formData.last_name,
      booking_id: id,
      isChecking: false
    };

    try {
      const result = await updateMyBooking(params, id, key);

      if (result.status === 200) {
        history.push({
          pathname: `/${key}/${id}/edit-booking-confirm`,
          state: {
            myBooking: {
              ...state.myBooking,
              party_size: formData.partySize,
              booking_date: formData.booking_date
            },
            businessName: state.businessName,
            businessAddress: state.businessAddress,
            widgetBranding: state.widgetBranding
          }
        });
      } else {
        console.error('Failed to update booking:', result.error);
        setErrorMessage(result.error || 'Failed to update booking. Please try again.');
      }
    } catch (error) {
      console.error('Error during booking update:', error);
      setErrorMessage('Failed to update booking. Please try again.');
    }
  };

  useEffect(() => {
    if (!state || !state.myBooking) {
      history.replace(`/${key}/${id}/booking-details`);
    }
  }, [state, history, key, id]);

  const useScrollToTop = () => {
    const { pathname } = useLocation();
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
  };

  useScrollToTop();
  return (
    <div className="wabi-root booking-management">
      <div className="wabi-container">
        <h1 className="wabi-header-ttl">Edit Booking</h1>
        <div className="allWrapper">
          <p>Current booking information</p>
          <div className="booking-card-wrapper">
            <BookingCard
              firstName={formData?.first_name}
              lastName={formData?.last_name}
              bookingDate={state?.myBooking?.booking_date}
              partySize={state?.myBooking?.party_size}
            />
          </div>
        </div>
        <div style={{ marginBottom: '40px', fontSize: '36px', color: '#444' }}>
          <FaArrowDown className="mx-auto" />
        </div>
        <div className="">
          <div className="" style={{ padding: '0 16px' }}>
            <p>Please select a new time slot</p>
          </div>
          <div className="" style={{ transform: 'scale(0.9)' }}>
            <EventBookFilter
              key={id}
              propDate={selectedDate}
              propPartySize={selectedPartySize}
              widgetBranding={formData.widgetBranding}
              EditBooking={true}
              onEditBooking={(data) => {
                setFormData((prev) => ({
                  ...prev,
                  booking_date: data.date_time,
                  partySize: data.party_size,
                  date: data.date,
                  time: data.time,
                  floor_id: data.floor_id,
                  shift_id: data.shift_id,
                  experience_id: data.experience_id
                }));
              }}
            />
          </div>
        </div>
        <div className="allWrapper">
          <p>Updated booking information</p>
          <div className="booking-card-wrapper">
            {formData.booking_date && formData.time ? (
              <BookingCard
                firstName={formData?.first_name}
                lastName={formData?.last_name}
                bookingDate={formData?.booking_date}
                partySize={formData?.partySize}
              />
            ) : (
              <p style={{ textAlign: 'center', color: '#888', fontSize: '14px' }}>
                Please select a new time slot from above to see updated booking details.{' '}
              </p>
            )}
          </div>
        </div>
        {errorMessage && (
          <p style={{ color: 'red', margin: '10px 0', textAlign: 'center' }}>{errorMessage}</p>
        )}
        <Button
          variant="outlined"
          style={{
            backgroundColor: '#000',
            color: '#fff',
            borderColor: '#fff',
            borderRadius: '5px',
            padding: '10px 0',
            width: '100%',
            textTransform: 'capitalize',
            fontFamily: 'Inter',
            fontSize: '14px',
            textAlign: 'center',
            marginTop: '40px'
          }}
          onClick={handleConfirm}
        >
          <span>Apply Changes</span>
        </Button>
        <div
          style={{
            borderTop: '1px solid',
            padding: '40px 20px',
            marginTop: '60px',
            marginBottom: '80px'
          }}
        >
          <ContactCard
            businessName={state?.businessName || ''}
            businessAddress={state?.businessAddress || ''}
            email={state?.myBooking?.owner.email || ''}
            phone={state?.myBooking?.owner.business_profile.phone_no || ''}
          />
        </div>
      </div>
      <PoweredBy className="pb-4" />
    </div>
  );
};

export default EditBooking;
