import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import logo from './../../assets/images/logo.png';

import { FaUserGroup } from 'react-icons/fa6';
import { IoIosCheckmarkCircleOutline } from 'react-icons/io';
import { FaCalendarCheck } from 'react-icons/fa';
import { MdOutlineAccessTimeFilled } from 'react-icons/md';
import { withStyles } from '@material-ui/core/styles';
import './step3.css';
import axios from 'axios';
import { URL } from './../../config';
import Spinner from 'react-bootstrap/Spinner';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { MdPeopleAlt } from 'react-icons/md';
import { Box } from '@material-ui/core';
import { MdChair } from 'react-icons/md';
import PoweredBy from '../common/powerdBy';

const styles = (theme) => ({
  root: {
    display: 'block'
  },
  formControl: {
    minWidth: 120
  }
});
class Step3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      key: this.props.match.params.key,
      title: document.getElementById('bookingWidgetRoot').getAttribute('data-title'),
      bookingId: this.props.match.params.booking_id,
      createdAt: null,
      formId: 'updateBooking',
      payment: null,
      appleWalletUrl: null,
      googleWalletUrl: null,
      downloadId: 0,
      isLoading: false,
      floorName: this.props?.location?.bookingData?.floorName,
      chooseFloor: false,
      displaySection: false
    };
  }
  componentWillMount() {
    if (this.props.location.bookingData !== undefined) {
      this.setState({
        bookingDate: this.props.location.bookingData.date,
        partySize: this.props.location.bookingData.partySize,
        time: this.props.location.bookingData.time,
        createdAt: this.props.location.bookingData.createdAt,
        payment: this.props.location.payment,
        title: this.props.location.bookingData.title,
        appleWalletUrl: this.props.location.bookingData.appleWalletUrl,
        googleWalletUrl: this.props.location.bookingData.googleWalletUrl,
        downloadId: this.props.location.bookingData.downloadId,
        floorName: this.props.location.bookingData.floorName,
        chooseFloor: this.props.location.bookingData.chooseFloor,
        displaySection: this.props.location.bookingData.displaySection,
        path: this.props.location.bookingData.path
      });
    } else if (this.props.location.ticketBookingData !== undefined) {
      this.setState({
        isTicket: true,
        title: this.props.location.ticketBookingData.title,
        countByName: this.props.location.ticketBookingData?.countByName,
        time: this.props.location.ticketBookingData.time,
        bookingDate: this.props.location.ticketBookingData.date,
        path: this.props.location.ticketBookingData.path
      });
    } else {
      this.props.history.go(-2);
    }
  }
  downloadPass = (type) => {
    this.setState({
      isLoading: true
    });
    const data = {
      downloadId: this.state.downloadId
    };
    axios({
      method: 'POST',
      url: `${URL}/widget/download_litecard`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.state.key}`
      },
      data: data
    })
      .then((response) => {
        this.setState({
          isLoading: false
        });
        const data = response.data.data;
        switch (type) {
          case 'googlePass':
            window.location.replace(data.downloadUri.googleLink);
            break;
          case 'applePass':
            window.location.replace(data.downloadUri.appleLink);
            break;
          default:
            break;
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        });
      });
  };
  render() {
    return (
      <div>
        <div className="booking_widget__app step3">
          <div className="content">
            <div className="check-container">
              <div className="check-step">
                <IoIosCheckmarkCircleOutline className="check-icon" />
                <p className="check-step-text">Complete</p>
              </div>
            </div>
            <p className="mb-0" style={{ fontSize: '36px' }}>
              Thank you!
            </p>
            {this.state?.isTicket ? (
              <p className="" style={{ fontSize: '24px', color: 'gray' }}>
                Your purchase is complete.
              </p>
            ) : (
              <p className="" style={{ fontSize: '24px', color: 'gray' }}>
                Your table has been confirmed.
              </p>
            )}
            <p>
              We'll send a confirmation email to your email address shortly. Please also check your
              spam folder.
            </p>
            <div className="info-wrap rounded-lg">
              <div className="icons">
                <FaCalendarCheck />
                <span>{this.state.bookingDate}</span>
              </div>
              <div className="icons">
                <MdOutlineAccessTimeFilled />
                <span>{this.state.time}</span>
              </div>
              {this.state?.isTicket ? (
                <>
                  <div className="icons">
                    {this.state &&
                      this.state?.countByName &&
                      Object.entries(this.state.countByName)
                        .filter(([_, { number }]) => number > 0)
                        .map(([name, { number, price }]) => (
                          <Box display="flex" className="item-center">
                            <MdPeopleAlt className="mr-2" />
                            <span>
                              <p style={{ fontSize: '14px' }} key={name} className="mb-0">
                                {number} x {name} Ticket ${price.toFixed(2)} Each
                              </p>
                            </span>
                          </Box>
                        ))}
                  </div>
                </>
              ) : (
                <>
                  <div className="icons">
                    <FaUserGroup />
                    <span>{this.state.partySize} guests</span>
                  </div>
                  {(this.state.chooseFloor || this.state.displaySection) && (
                    <div className="icons">
                      <MdChair />
                      <span style={{ fontSize: '14px' }}>Floor: {this.state.floorName}</span>
                    </div>
                  )}
                </>
              )}
              <div className="mt-4">
                <Button
                  variant="contained"
                  color="submit"
                  component={Link}
                  to={`${this.state.path}${this.state.key}`}
                  className="text-white rounded-lg"
                  style={{
                    background: '#222a2f',
                    textTransform: 'none',
                    width: '100%'
                  }}
                >
                  Back to Home
                </Button>
              </div>
            </div>
            <Row>
              <Col xs lg="12" className="text-center">
                <PoweredBy />
              </Col>
            </Row>
            {this.state.isLoading ? (
              <Row>
                <Col
                  fluid
                  style={{
                    textAlign: 'center'
                  }}
                >
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                </Col>
              </Row>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Step3);
