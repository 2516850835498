import React from 'react';
import Typography from '@material-ui/core/Typography';

const TruncateTypography = ({ text, maxChars, widgetBranding }) => {
  const truncatedText = text
    ? text.length > maxChars
      ? `${text.substring(0, maxChars)}[...]`
      : text
    : '';

  return (
    <Typography
      variant="body1"
      style={{
        fontSize: '1em',
        fontFamily: widgetBranding.font ? widgetBranding.font : 'inherit',
        wordBreak: 'break-word'
      }}
      component="div"
    >
      {truncatedText}
    </Typography>
  );
};

export default TruncateTypography;
