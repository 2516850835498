import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText
} from '@material-ui/core';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useParams } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { MdPeopleAlt } from 'react-icons/md';
import Countdown from '../countdown';
import requestPayTicket from '../../hooks/@events/requestPayTicket';
import PoweredBy from '../common/powerdBy';
const TicketBookingForm = (props) => {
  const formId = 'updateBooking';
  const [loading, setLoading] = useState(false);
  const { date, time, countByName, ticket_name, price, property, widgetNoteTitle, uuid } = props;
  let timeTillDate = 300;
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const displayAlertMessage = (message) => {
    setMessage(message);
    handleClickOpen();
  };
  const stripe = useStripe();
  const elements = useElements();
  const { key } = useParams();
  const [cardComplete, setCardComplete] = useState(false);
  const handleChange = (event) => {
    setCardComplete(event.complete);
  };
  const handleSubmit = async (fields) => {
    try {
      setLoading(true);
      if (!stripe || !elements) {
        return;
      }
      if (!cardComplete) {
        displayAlertMessage('Please complete all card details.');
        return;
      }
      const cardElement = elements.getElement(CardElement);
      const { stripeError, token } = await stripe.createToken(cardElement);
      if (stripeError) {
        console.log('[createToken error]', stripeError);
        setMessage(stripeError.message);
        setLoading(false);
        return;
      }
      await handlePayTickets(fields, token.card, token.id);
    } catch (error) {
      displayAlertMessage('Payment failed');
      console.error(error);
      setLoading(false);
    }
  };
  const initialValues = () => {
    return {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      request: ''
    };
  };
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    email: Yup.string().email('Email is invalid').required('Email is required'),
    phoneNumber: Yup.string().required('Phone Number is required')
  });
  const newLineMessage = (text) => {
    if (text === null || text === '' || text === undefined) {
      return '';
    }
    const txt = text;
    const newText = txt.split('\n').map((str, index) => <p key={index}>{str}</p>);
    return newText;
  };
  const handlePayTickets = async (fields, cardInfo, token) => {
    try {
      const data = {
        uuid: uuid,
        first_name: fields.firstName,
        last_name: fields.lastName,
        phone_number: fields.phoneNumber,
        email: fields.email,
        request: 'payment',
        currency: 'aud',
        token: token,
        card: {
          brand: cardInfo.brand,
          last4: cardInfo.last4,
          exp_month: cardInfo.exp_month,
          exp_year: cardInfo.exp_year
        }
      };
      const response = await requestPayTicket(key, data);
      if (response.data?.requires_action) {
        stripe.confirmCardPayment(response.data?.payment_intent_client_secret).then((result) => {
          if (result.error) {
            console.error('Error:', result.error.message);
            displayAlertMessage(result.error.message);
          } else {
            transitionComplete();
          }
        });
      } else if (response.data?.requires_payment_method) {
        stripe
          .confirmCardPayment(response.data?.payment_intent_client_secret, {
            payment_method: {
              card: elements.getElement(CardElement)
            }
          })
          .then((result) => {
            if (result.error) {
              displayAlertMessage(result.error.message);
            } else {
              transitionComplete();
            }
          });
      } else if (response.data?.success) {
        transitionComplete();
      }
    } catch (error) {
      setLoading(false);
      displayAlertMessage('Payment failed');
      console.error('Payment failed:', error);
    }
  };
  const transitionComplete = () => {
    const ticketBookingData = {
      date: date,
      time: time,
      countByName: countByName,
      title: ticket_name,
      path: props.path
    };

    property.history.push({
      pathname: `/step3/${key}`,
      ticketBookingData: ticketBookingData
    });
  };
  return (
    <div className="booking_widget__app step2 ticket-form">
      <div>
        <h1> {ticket_name}</h1>
        <Countdown secondsRemaining={timeTillDate} />
        <div>
          <div>
            <div className="info-container rounded-lg mb-5">
              <div className="icons">
                {Object.entries(countByName)
                  .filter(([_, { number }]) => number > 0)
                  .map(([name, { number, price }]) => (
                    <Box display="flex" className="mb-2" key={name}>
                      <MdPeopleAlt className="mr-2" />
                      <span>
                        <p className="mb-0" style={{ fontSize: '14px' }}>
                          {number} x {name} Ticket ${price.toFixed(2)} Each
                        </p>
                      </span>
                    </Box>
                  ))}
              </div>
              <hr style={{ borderColor: '#222a2f' }} />
              <div className="icons text-right">
                <strong>
                  <span style={{ fontSize: '14px' }}>Total price: ${price}</span>
                </strong>
              </div>
            </div>
          </div>
          <div className="">
            <Formik
              initialValues={initialValues()}
              validationSchema={validationSchema}
              onSubmit={async (fields) => {
                await new Promise((r) => setTimeout(r, 500));
                handleSubmit(fields);
              }}
            >
              {({ errors, dirty, touched, isValid, isSubmitting }) => (
                <Form id={formId}>
                  <div>
                    <div>
                      <div className="form-group">
                        <label style={{ fontSize: '14px', marginLeft: '5px' }}>First Name</label>
                        <Field
                          style={{
                            display: 'block',
                            fontSize: 12,
                            color: '#222a2f',
                            boxShadow: 'rgb(0 0 0 / 16%) 0px 2px 6px',
                            border: 'none'
                          }}
                          placeholder="First Name"
                          name="firstName"
                          type="text"
                          className={
                            'rounded-lg form-control' +
                            (errors.firstName && touched.firstName ? ' is-invalid' : '')
                          }
                        />
                        <ErrorMessage
                          name="firstName"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                    <div>
                      <div className="form-group">
                        <label style={{ fontSize: '14px', marginLeft: '5px' }}>Last Name</label>
                        <Field
                          style={{
                            display: 'block',
                            fontSize: 12,
                            color: '#222a2f',
                            boxShadow: 'rgb(0 0 0 / 16%) 0px 2px 6px',
                            border: 'none'
                          }}
                          placeholder="Last Name"
                          name="lastName"
                          type="text"
                          className={
                            'rounded-lg form-control' +
                            (errors.lastName && touched.lastName ? ' is-invalid' : '')
                          }
                        />
                        <ErrorMessage
                          name="lastName"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <div>
                      <div className="form-group">
                        <label style={{ fontSize: '14px', marginLeft: '5px' }}>Phone Number</label>
                        <Field
                          style={{
                            display: 'block',
                            fontSize: 12,
                            color: '#222a2f',
                            boxShadow: 'rgb(0 0 0 / 16%) 0px 2px 6px',
                            border: 'none'
                          }}
                          placeholder="Phone Number"
                          name="phoneNumber"
                          type="text"
                          className={
                            'rounded-lg form-control' +
                            (errors.phoneNumber && touched.phoneNumber ? ' is-invalid' : '')
                          }
                        />
                        <ErrorMessage
                          name="phoneNumber"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                    <div>
                      <div className="form-group">
                        <label style={{ fontSize: '14px', marginLeft: '5px' }}>Email</label>
                        <Field
                          style={{
                            display: 'block',
                            fontSize: 12,
                            color: '#222a2f',
                            boxShadow: 'rgb(0 0 0 / 16%) 0px 2px 6px',
                            border: 'none'
                          }}
                          placeholder="Email"
                          name="email"
                          type="text"
                          className={
                            'rounded-lg form-control' +
                            (errors.email && touched.email ? ' is-invalid' : '')
                          }
                        />
                        <ErrorMessage name="email" component="div" className="invalid-feedback" />
                      </div>
                    </div>
                  </div>
                  <label style={{ fontSize: '14px', marginLeft: '5px' }}>Card</label>
                  <div
                    style={{
                      padding: 13,
                      height: 40,
                      display: 'block',
                      fontSize: 12,
                      boxShadow: 'rgb(0 0 0 / 16%) 0px 2px 6px',
                      border: 'none'
                    }}
                    className=" rounded-lg"
                  >
                    <CardElement
                      onChange={handleChange}
                      options={{
                        hidePostalCode: true,
                        style: {
                          base: {
                            display: 'block',
                            fontSize: 12,
                            boxShadow: 'rgb(0 0 0 / 16%) 0px 3px 6px',
                            border: 'none',
                            color: '#707070',
                            height: '80px',
                            '::placeholder': {
                              color: '#B3B3B3'
                            }
                          },
                          invalid: {
                            color: '#9e2146'
                          }
                        }
                      }}
                    />
                  </div>
                  <div
                    style={{
                      display: 'block',
                      margin: 'auto',
                      marginTop: 22,
                      fontSize: 12,
                      color: '#222a2f'
                    }}
                  >
                    <div className="mb-2 font-semibold">Cancellation Policy</div>
                    <div>
                      Prepaid ticket cannot be modified or exchanged. Please call us if changes are
                      needed.
                    </div>
                  </div>
                  <Row>
                    <Col xs="12" lg="12" className="text-center">
                      <button
                        form={formId}
                        variant="contained"
                        type="submit"
                        style={{
                          textTransform: 'none'
                        }}
                        className="submit rounded-lg mt-4 text-white w-full bg-[#222a2f] disabled:bg-[#ccc] disabled:cursor-not-allowed"
                        disabled={!dirty || !isValid || isSubmitting || loading || !cardComplete}
                      >
                        {loading ? 'Proceeding...' : 'Buy Tickets'}
                      </button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </div>
          {(widgetNoteTitle !== null && widgetNoteTitle !== null) ||
          (widgetNoteTitle !== '' && widgetNoteTitle !== '') ? (
            <div>
              <div>
                <h2 className="afterHrh2"> {widgetNoteTitle} </h2>
              </div>
              <div>
                <p>{newLineMessage(widgetNoteTitle)}</p>
              </div>
            </div>
          ) : (
            <div>
              <div>
                <h2 className="afterHrh2"> What to know before you go </h2>
              </div>
              <div>
                <h3>Important dining information</h3>
                <p>
                  We have a 15 minute grace period. Please call us if you are running later than 15
                  minutes after your reservation time.
                </p>
              </div>
              <div>
                <h3>We may contact you about this</h3>
                <p>reservation, so please ensure your email and phone number are up to date...</p>
              </div>
              <div>
                <h3>A note from the restaurant</h3>
                <p>
                  If you would like a rooftop table for dining, please specify - once all tables on
                  the roof have been reserved bookings will be allocated indoors. Please call if you
                  have any concerns. All diners have the table for a maximum of 2 hours.
                </p>
              </div>
            </div>
          )}
          <div className="mb-5">
            <Col xs lg="12" className="text-center">
              <p className="footer">
                "Confirm Reservation" you are agreeing to the&nbsp;
                <a href="https://javascriptpros.com" rel="noopener noreferrer" target="_blank">
                  terms and conditions
                </a>{' '}
                of the{' '}
                <a href="https://javascriptpros.com" rel="noopener noreferrer" target="_blank">
                  WABI User Agreement
                </a>
              </p>
            </Col>
          </div>
          <div>
            <Col xs lg="12" className="text-center">
              <PoweredBy />
            </Col>
          </div>
        </div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">{`${message}`}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Okay
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};
export default TicketBookingForm;
