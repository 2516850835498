import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
  Button,
  Grid
} from '@material-ui/core';
import { MdOutlineClose } from 'react-icons/md';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import { FaCalendarCheck, FaUserGroup } from 'react-icons/fa6';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { makeReservation } from '../../hooks/@booking/makeReservation';
import { useParams } from 'react-router-dom';
import Toast from '../../common/ui/toast';
import { makeStyles } from '@material-ui/core/styles';
const useStylesCustom = makeStyles((theme) => ({
  datePickerInput: {
    padding: '8px !important',
    width: '100%'
  }
}));

const RequestModal = ({
  open,
  onClose,
  widgetBranding,
  selectedDate,
  selectedPartySize,
  enableSelectDatePartysize
}) => {
  const { key } = useParams();
  const [toast, setToast] = useState({ message: '', type: '' });
  const [requestedDate, setRequestedDate] = useState(selectedDate || new Date());
  const [requestedPartySize, setRequestedPartySize] = useState(selectedPartySize || 2);

  // Show toast message
  const showToast = (message, type = 'success') => {
    setToast({ message, type });
  };

  // Styles
  const dialogStyle = {};
  const buttonStyle = {
    color: '#fff',
    backgroundColor: '#111',
    padding: '8px 0',
    borderRadius: '4px'
  };
  const classesCustom = useStylesCustom({ accent_colour: widgetBranding.accent_colour || '#fff' });

  // Select Date
  const handleDateChange = (date) => {
    setRequestedDate(date);
    console.log('requestedDate:', moment(date).format('YYYY-MM-DD'));
  };
  // Validation schema for Formik
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    phoneNumber: Yup.string().required('Phone Number is required'),
    reservationNotes: Yup.string().required('Reservation Notes is required')
  });

  // Submit handler for Formik
  const handleSubmit = async (values, { setSubmitting }) => {
    const params = {
      guest: {
        first_name: values.firstName,
        last_name: values.lastName,
        phone: values.phoneNumber,
        email: values.email
      },
      party_size: selectedPartySize,
      date: moment(selectedDate).format('YYYY-MM-DD'),
      start_date: `${moment(selectedDate).format('YYYY-MM-DD')} 12:00:00`,
      end_date: `${moment(selectedDate).format('YYYY-MM-DD')} 12:00:00`,
      reservation_note: values.reservationNotes
    };
    if (enableSelectDatePartysize) {
      params.date = moment(requestedDate).format('YYYY-MM-DD');
      params.start_date = `${moment(requestedDate).format('YYYY-MM-DD')} 12:00:00`;
      params.end_date = `${moment(requestedDate).format('YYYY-MM-DD')} 12:00:00`;
      params.party_size = requestedPartySize;
    }
    try {
      await makeReservation(params, key);
      setSubmitting(false);
      showToast('Reservation request submitted successfully!', 'success');
      onClose();
    } catch (error) {
      console.error('Error submitting reservation:', error);
      setSubmitting(false);
      showToast('Failed to submit reservation. Please try again.', 'error');
    }
  };

  useEffect(() => {
    console.log(selectedPartySize, 'selectedPartySize');
  }, [open, selectedPartySize]);

  return (
    <>
      {toast.message && (
        <Toast
          message={toast.message}
          type={toast.type}
          onClose={() => setToast({ message: '', type: '' })}
        />
      )}
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          style: dialogStyle
        }}
      >
        <DialogTitle>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6">Request Details</Typography>
            <Button onClick={onClose} style={{ minWidth: 'auto' }}>
              <MdOutlineClose size={24} />
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              firstName: '',
              lastName: '',
              email: '',
              phoneNumber: '',
              reservationNotes: ''
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <Box display="flex" flexDirection="column">
                  {enableSelectDatePartysize ? (
                    <Grid container spacing={2} mt={2}>
                      {/* Date Picker */}
                      <Grid item xs={12} sm={6}>
                        <div className="box-shadow-none">
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <div
                              style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}
                            >
                              <FaCalendarCheck size={14} style={{ marginRight: '0.4em' }} />
                              <Typography variant="subtitle2">Select a Date</Typography>
                            </div>
                            <div
                              style={{
                                border: '1px solid #ccc',
                                borderRadius: '4px'
                              }}
                            >
                              <KeyboardDatePicker
                                variant="inline"
                                id="date-picker-inline"
                                format="EEE, MMM d"
                                value={requestedDate || new Date()}
                                onChange={handleDateChange}
                                minDate={new Date()}
                                maxDate={new Date('5000-01-01')}
                                autoOk={true}
                                className="datepicker"
                                disablePast
                                style={{ width: '100%', padding: '0px' }}
                                InputProps={{
                                  readOnly: true,
                                  classes: {
                                    input: classesCustom.datePickerInput
                                  }
                                }}
                                KeyboardButtonProps={{
                                  'aria-label': 'change date',
                                  className: 'custom-calendar-icon'
                                }}
                              />
                            </div>
                          </MuiPickersUtilsProvider>
                        </div>
                      </Grid>

                      {/* Partysize Input */}
                      <Grid item xs={12} sm={6}>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                          <FaUserGroup size={14} style={{ marginRight: '0.4em' }} />
                          <Typography variant="subtitle2">Select Partysize</Typography>
                        </div>
                        <input
                          value={requestedPartySize || 2}
                          type="number"
                          placeholder="Partysize"
                          className="form-control"
                          onChange={(e) => setRequestedPartySize(Number(e.target.value))}
                          style={{
                            width: '100%',
                            padding: '8px',
                            border: '1px solid #ccc',
                            borderRadius: '4px'
                          }}
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    <>
                      <div style={{ display: 'flex', alignItems: 'center' }} className="mb-3">
                        <FaCalendarCheck size={16} style={{ marginRight: '0.4em' }} />
                        <Typography>{moment(selectedDate).format('ddd, MMM D')}</Typography>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center' }} className="mb-3">
                        <FaUserGroup size={16} style={{ marginRight: '0.4em' }} />
                        <Typography>{selectedPartySize} Guest</Typography>
                      </div>
                    </>
                  )}

                  <Box mt={2}>
                    <Typography variant="subtitle2">Reservation Notes:*</Typography>
                    <Field
                      as="textarea"
                      name="reservationNotes"
                      rows={5}
                      className="form-control"
                      placeholder="Write your preferred time slot and any other requests here…"
                    />
                    <ErrorMessage
                      name="reservationNotes"
                      component="div"
                      style={{ color: 'red', fontSize: '12px' }}
                    />
                  </Box>
                  <Box mt={2}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle2">First Name*</Typography>
                        <Field
                          name="firstName"
                          type="text"
                          placeholder="First Name"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="firstName"
                          component="div"
                          style={{ color: 'red', fontSize: '12px' }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle2">Last Name*</Typography>
                        <Field
                          name="lastName"
                          type="text"
                          placeholder="Last Name"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="lastName"
                          component="div"
                          style={{ color: 'red', fontSize: '12px' }}
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  <Box mt={2}>
                    <Grid container spacing={2} mt={2}>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle2">Email Address*</Typography>
                        <Field
                          name="email"
                          type="email"
                          placeholder="Email Address"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          style={{ color: 'red', fontSize: '12px' }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle2">Phone Number*</Typography>
                        <Field
                          name="phoneNumber"
                          type="phone"
                          placeholder="Phone Number"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="phoneNumber"
                          component="div"
                          style={{ color: 'red', fontSize: '12px' }}
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  <Box mt={4}>
                    <Button
                      variant="contained"
                      style={buttonStyle}
                      fullWidth
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? 'Submitting...' : 'Make a reservation request'}
                    </Button>
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default RequestModal;
