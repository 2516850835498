import React from 'react';
import logo from '../../assets/images/logo.png';

const PoweredBy = ({ className = '' }) => {
  return (
    <p className={`booking_widget__powered_by flex gap-1 ${className}`}>
      Powered by
      <a href="https://wabify.com" rel="noopener noreferrer" target="_blank">
        <img className="w-[70px]" src={logo} alt="logo" />
      </a>
    </p>
  );
};

export default PoweredBy;
