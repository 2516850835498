import React, { useState, useEffect } from 'react';
import TruncateTypography from '../common/TruncateTypography';

const EventDescription = ({ isExperience, eventData, widgetBranding }) => {
  const [isMobile, setIsMobile] = useState(
    typeof window !== 'undefined' ? window.innerWidth <= 768 : false
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const maxChars = isMobile ? 60 : 280;

  return (
    <>
      <TruncateTypography
        text={isExperience ? eventData.data.exp_description : eventData.data.description}
        maxChars={maxChars}
        widgetBranding={widgetBranding}
      />
    </>
  );
};

export default EventDescription;
