import React, { useState } from 'react';

const ExpandableText = ({ text = '', maxLength = 200, widgetBranding }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const isLongText = text.length > maxLength;

  const displayText = isExpanded || !isLongText ? text : text.slice(0, maxLength) + '...';

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <p
      className="event-description"
      style={{
        color: widgetBranding.accent_colour || '#fff',
        fontFamily: widgetBranding.font ? widgetBranding.font : 'inherit',
        wordBreak: 'break-word',
        fontSize: '1rem'
      }}
    >
      {displayText}
      {isLongText && (
        <span
          onClick={handleToggle}
          style={{
            color: widgetBranding.book_now_colour || '#2020B0',
            fontWeight: 'bold',
            cursor: 'pointer',
            marginLeft: '5px'
          }}
        >
          {isExpanded ? '-Less' : '+More'}
        </span>
      )}
    </p>
  );
};

export default ExpandableText;
